<template>
<v-input
  v-bind="$attrs"
  :value="value"
  class="d-flex flex-column"
> 
    <template v-slot:prepend>
      <v-subheader>

      {{ label}}
      </v-subheader>
    </template>
    <v-row id="table-add">
      <v-col class="align-center justify-center d-flex">
          <div class="table table-square" :class="{ selected: value == 'square' }" @click="$emit('input', 'square')">
            {{ 'square_table' | t }}
          </div>
      </v-col>
      <v-col class="align-center justify-center d-flex">
          <div class="table table-circle" :class="{ selected: value == 'circle' }" @click="$emit('input', 'circle')">
            {{ 'circle_table' | t }}
          </div>
      </v-col>
      <v-col class="align-center justify-center d-flex">
          <div class="table table-rect" :class="{ selected: value == 'rect' }" @click="$emit('input', 'rect')">
            {{ 'rect_table' | t }}
          </div>
      </v-col>
      <v-col class="align-center justify-center d-flex">
          <div class="table stripes" :class="{ selected: value == 'spacer' }" @click="$emit('input', 'spacer')">
            <span style="background: rgba(196, 196, 196, 0.418);" class="pa-2">{{ 'spacer' | t }} </span>
          </div>
      </v-col>
    </v-row>
</v-input>
</template>

<script>
 
export default {
  props: ['value', 'label']
 
}
</script>

<style lang="scss">
#table-add {
  .stripes {
    background-color: #e5e5f7 !important;
    background: repeating-linear-gradient( 45deg, #a5a5a5, #a5a5a5 2px, #e5e5f7 2px, #e5e5f7 10px ) !important;
      height: 100px;
    width: 100px;
   
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table.selected {
    border-color: #38b6ff !important;
  }
  .table {
    height: 100px;
    width: 100px;
    border: 2px solid #7fcffd5e !important;
    background: rgba(196, 196, 196, 0.418);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table-circle {
    border-radius: 50%;
  }
  .table-rect {
     width: 200px;
  }
}

</style>